/* index.css or App.css */

/* Light Theme (default) */
:root {
  --background-color: #f0f2f5;
  --card-background-color: white;
  --text-color: black;
}

/* Dark Theme */
body.dark {
  --background-color: #1e1e1e;
  --card-background-color: #2c2c2c;
  --text-color: white;
}

/* Apply these variables to your entire app */
body {
  background-color: var(--background-color);
  color: var(--text-color);
}
