body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Light Theme */
:root {
  --background-color: #f0f2f5;
  --card-background-color: white;
  --text-color: black;
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --button-text-color: white;
  --input-border-color: #ccc;
  --input-background-color: white;
}

/* Dark Theme */
body.dark {
  --background-color: #1e1e1e;
  --card-background-color: #2c2c2c;
  --text-color: white;
  --primary-color: #007bff; /* You can adjust this for dark mode if needed */
  --primary-hover-color: #0056b3; /* You can adjust this for dark mode if needed */
  --button-text-color: white;
  --input-border-color: #444;
  --input-background-color: #333;
}
